<template>
  <div>
    <div class="splash-container min-vh-100">
      <div class="card card-border-color card-border-color-success">
        <div class="card-header">
          <img
            class="logo-img"
            src="/img/brand/logo.png"
            alt="Mshiriki"
            width="180"
            height="70"
          />
          <h3 class="text-uppercase">Password Reset</h3>
          <hr />
          <p>Enter your email to receive password reset instructions.</p>
        </div>
        <div class="card-body">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <form method="post">
            <div class="form-group">
              <label for="id_email">E-mail:</label>
              <input
                v-model.trim="user.email"
                type="email"
                name="email"
                size="30"
                placeholder="E-mail address"
                class="form-control"
                required
                id="id_email"
              />
            </div>
            <div class="form-group">
              <button
                :disabled="submitting"
                @click="reset()"
                class="btn btn-primary btn-block"
                type="button"
              >
                {{ submit }}
              </button>
            </div>
            <div class="form-group text-center">
              <router-link :to="{ name: 'login' }">Back to Login</router-link>
            </div>
          </form>
        </div>
      </div>
      <div class="splash-footer text-white">
        <span>© {{ year }} ARED</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "reset",
  data() {
    return {
      submitting: false,
      user: {
        email: "",
      },
      error: "",
      submit: "Reset Password",
      year: new Date().getFullYear(),
    };
  },
  methods: {
    reset() {
      this.error = "";
      this.submitting = true;
      this.$validator
        .validateAll()
        .then((result) => {
          if (!result) {
            this.submitting = false;
            return;
          }
          this.submit = "Requesting....";

          const subdomain = window.location.host;
          const protocol = window.location.protocol;
          const domain = protocol + "//" + subdomain;

          const payload = {
            email: this.user.email,
            site: { url: domain, name: "Admin Dashboard" },
          };

          axios.post("v1/auth/users/reset_password/", payload).then(
            () => {
              this.user = {
                email: "",
              };
              this.$validator.reset();
              this.submit = "Reset Password";
              // eslint-disable-next-line no-console
              this.$swal(
                "Success",
                "Password reset link has been sent to your email. Click on the link to reset your password.",
                "success"
              );
              router.push({ name: "login" });
            },
            (error) => {
              console.log(error.response);
              this.submit = "Reset Password";
              this.submitting = false;
              this.error = error.response.data.detail;
              // eslint-disable-next-line no-console

              console.log("PASSWORD RESET ERROR: ", error);
            }
          );
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>
<style></style>
