<template>
  <div>
    <password-forgot-form />
  </div>
</template>
<script>
import PasswordForgotForm from "@/components/Auth/ForgotPasswordForm";

export default {
  components: { PasswordForgotForm },
};
</script>
<style></style>
